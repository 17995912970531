<template>
  <div>
    <div>
      <a-row>
        <a-col :span="12">
          <a-form-item label="公司主体">
            <span>{{ listData.companySubject || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="签约时间">
            <span>{{ getDate(listData.signingTime) || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="机构名">
            <span>{{ listData.name || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="机构评分">
            <span>{{ listData.systemScore || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="联系人">
            <span>{{ listData.contact || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="手机号">
            <span>{{ listData.phoneNumber || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="省市区">
            <span>{{ listData.provinceCityDistrict || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="产品最低价格(人民币)">
            <span>{{ listData.minPrice || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="详细地址">
            <span>{{ listData.detailedAddress || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="业务类型">
            <span>{{ listData.businessType || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="申请国家/地区">
            <span>{{ listData.countryOfApplication || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="接待城市">
            <span>{{ listData.receptionCity || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="备注">
            <span>{{ listData.remarks || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <div style="font-size: 20px; padding: 20px 0;">CRM系统信息</div>
      <a-row>
        <a-col :span="12">
          <a-form-item label="是否开通CRM">
            <span>{{ listData.crm ? '是' : '否' || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="CRM网址">
            <span>{{ listData.domainName || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <div style="font-size: 20px; padding: 20px 0;">机构关系</div>
      <a-row>
        <a-col :span="12">
          <a-form-item label="创建人">
            <span>{{ listData.attributablePerson || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="创建时间">
            <span>{{ getDate(listData.createdDate) || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="业务归属人">
            <span>{{ listData.activate || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="状态">
            <span>{{ listData.locked ? '停用' : '启用' || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { get } from "@/utils/http";
import TablePage from "@/components/TablePage";
import { customerStage, intentionLevel, customerStatus, starRating } from '@/utils/public';
import dayjs from "dayjs";
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "intentionDetail",
  data() {
    return {
      detailLoading: false,
      listData: {},
    }
  },
  created() {
    this.getDetail(this.selectedRows[0])
  },
  methods: {
    async getDetail(row) {
      this.detailLoading = true;
      try {
        let res = await get(`/agency/institutionsAudit/getInstitutions?id=${row.id}`)
        this.listData = res.data;

      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },

    getDate(val) {
      if(val) {
        return dayjs(val).format('YYYY-MM-DD: HH:mm:ss')
      } else {
        return '-'
      }
    }

  }
}
</script>

<style scoped>
.detail-title {
  font-weight: 550;
}

.detail-text {}

.ant-col {
  margin-top: 20px;
}

.user_image {
  max-width: 224px;
  max-height: 126px;
}
</style>
